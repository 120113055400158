import * as React from "react";
import { PropsWithChildren } from "react";
import JitsuContext, { JitsuInstance } from "./JitsuContext";
import { emptyAnalytics, jitsuAnalytics } from "@jitsu/js";
import { ExtendedJitsuOptions } from "./useJitsu";

const JitsuProvider: React.FC<PropsWithChildren<{ options: ExtendedJitsuOptions }>> = props => {
  let instance: JitsuInstance;
  if (props.options.disabled) {
    instance = { analytics: emptyAnalytics };
  } else if (!props.options.host) {
    const msg = "<JitsuProvider />. Jitsu host is not defined. Jitsu will not be initialized";
    console.error(`%c${msg}`, "color: red; font-weight: bold;");
    instance = { analytics: emptyAnalytics };
  } else {
    instance = { analytics: jitsuAnalytics(props.options) };
  }
  return <JitsuContext.Provider value={instance}>{props.children}</JitsuContext.Provider>;
};

export default JitsuProvider;
